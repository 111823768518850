body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;

  background-color: #d3d3d3;

  background-color: var(--mainBackgroundColor);

  padding: 10px;


}

:root {
    --mainPrimaryColor:#8fbc8f;   
    --mainSecondaryColor: #0e551a;
    --mainTernaryColor: #4e9e37;

    --mainBackgroundColor: #d3d3d3; 
}

/*


Default Override


*/


.menuLoginButton {
  color: #4e9e37;
  color: var(--mainTernaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);
  
  margin: 20px;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;  
  border-width: 1px;
  border-style: solid;

}

.menuLoginButton:focus {
  color: #4e9e37;
  color: var(--mainTernaryColor);
  background-color: #8fbc8f;
  background-color: var(--mainPrimaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

  outline: none;
  box-shadow: none;
  
}

.menuLoginButton:link {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}

.menuLoginButton:visited {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}

.menuLoginButton:hover {
  color: #4e9e37;
  color: var(--mainTernaryColor);
  background-color: #8fbc8f;
  background-color: var(--mainPrimaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}

.menuLoginButton:active {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}




.mainContainer{

    background-color: #8fbc8f;

    background-color: var(--mainPrimaryColor);

    padding-top: 50px;
    padding-bottom: 1px;

}

.mainHeaderCenter {

  margin-top: 20px;

}

.pageMain {

  margin-left: 30px;
  margin-right: 30px;

  padding-top: 5px;
  padding-bottom: 20px; 

}

.pageMain h1 {

  text-align: left;
  padding-left: 20px;


}


.errorColor {
  color: red;

}


/*

    Header Menu Items

*/


.navigationContainer {
  background-color: blue;

}

.menuContainer {
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  margin: 30px;

  display: flex;

}

.menuContainer a:hover {
  color:    #4e9e37;
  color:    var(--mainTernaryColor);
  text-decoration: none;

}

.menuContainer a:link, a:visited, a:active {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);

}

.menuHome {

  background-color: #0e551a;

  background-color: var(--mainSecondaryColor);

  
  border: none;

  margin-top: auto; 
  margin-bottom: auto;

  font-size: 120%;
  margin-right: 10px;


}

.menuItem {
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);

  /* display: flex; */

  color: #8fbc8f;

  color: var(--mainPrimaryColor);

  margin-top: auto;
  margin-bottom: auto;

  margin-left: 3px;

  border: none;

}

/*

  Login Form login button

*/ 

.loginButton {
  color: #4e9e37;
  color: var(--mainTernaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);
  
  margin: 20px;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;  
  border-width: 1px;
  border-style: solid;
  
}

.loginButton:focus {
  color: #4e9e37;
  color: var(--mainTernaryColor);
  background-color: #8fbc8f;
  background-color: var(--mainPrimaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

  outline: none;
  box-shadow: none;

}

.loginButton:link {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}

.loginButton:visited {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}

.loginButton:hover {
  color: #4e9e37;
  color: var(--mainTernaryColor);
  background-color: #8fbc8f;
  background-color: var(--mainPrimaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}

.loginButton:active {
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);

}


.formLabel {
  width: 100px;
 
  text-align: right;
  margin-left: 20px;
  margin-right: 10px;

  padding: 5px;   

}

.formInput {

  border-radius: 15px;
  padding: 5px;
  padding-left: 10px;

  border-width: 0px;
  
}

.formInput:focus {
 
  outline: none;
  box-shadow: none;

}

.formButton {
  
  outline: none;
 

}

.formLogin {
  margin-top: 50px;

}


.advertiserContainer {
  /* columns: 2 auto; */
  background-color: red;
}

.advertiserHeading  {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  margin-top: 20px;

  margin-bottom: 15px;

  display: inline-block;
  text-align: center;

}

.advertiserCell {
    background-color: #8fbc8f;
    background-color: var(--mainPrimaryColor);
    color: #0e551a;
    color: var(--mainSecondaryColor);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;

    border-color: #4e9e37;

    border-color: var(--mainTernaryColor);

    height: 300px;
    margin: 10px;
    padding: 10px;
}

.advertiserRow {
  margin-bottom: 2px;
}

.advertiserDetail {
  margin-top: 10px;
}

.advertiserLine {

  border-style: solid;
  border-width: 3px;
  border-radius: 8px;

  border-color: yellow;

  margin-left: 20px;
}

.advertisersAnchor  {
  color: blue;

}


.advertisersAnchor:focus {
  color: lightblue;
  
}

.advertisersAnchor:link {
  color: blue;

}

.advertisersAnchor:visited {
  color: darkblue;

}

.advertisersAnchor:hover {
  color: purple;

}

.advertisersAnchor:active {
  color: navy;
}





.homeContainer {
  margin-top: 20px;
  margin-bottom: 10px;
}

.homeColumnCenter {
    margin-top: auto;
    margin-bottom: auto;

} 

.footerRow {
  background-color:  #0e551a;
  background-color:  var(--mainSecondaryColor);
  color: #4e9e37;
  color: var(--mainTernaryColor);
 
  margin-left: 40px;
  margin-right: 40px;

  margin-top: 20px;
  margin-bottom: 20px;
}

.footerColumn {
   background-color: #0e551a;
   background-color: var(--mainSecondaryColor);
   color: #4e9e37;
   color: var(--mainTernaryColor);
  
}

/*
  Officers Page
*/
.officersOfficerHeading {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  margin-top: 50px;
  
}

.officersTrusteeHeading {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  margin-top: 50px;
  
}

.officersTitle {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  text-align: right;
  color:  #0e551a;
  color:  var(--mainSecondaryColor);
  font-size: large;
  font-weight: bolder;

}

.officersOfficer {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  text-align: left;
  font-size: large;
  color:  maroon;
  font-weight: bolder;
  
}

.officersListGroupItem {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  border-width: 0px;
  font-weight: bolder;
  font-size: large;
  color: maroon;
  padding: 2px;
}


/*
  Home Page
*/
.donationListGroup {
 
  margin-top: 30px;
}

.owlofthemonthListGroup {
 
  margin-top: 30px;
}

.owlofthemonthHeader {

  margin-bottom: 20px;
}

.donationHeader {

  margin-bottom: 20px;
}

.donationListGroupItem {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  border-width: 0px;
  font-weight: bolder;
  font-size: large;
  color: maroon;
  padding: 2px;
}

.owlofthemonthListGroupItem {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  border-width: 0px;
  font-weight: bolder;
  font-size: large;
  color: maroon;
  padding: 2px;
}

/*

    Archive Page

*/

.archiveHeading  {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  margin-top: 20px;

  display: inline-block;
  text-align: center;

}

.archiveYear {

  margin: 30px;
  font-weight: bolder;
  font-size: 36px;
  color: maroon;
}

.archiveButton {
  background-color: #0e551a;
  background-color: var(--mainSecondaryColor);
  color: #8fbc8f;
  color: var(--mainPrimaryColor);
  border-color: #4e9e37;
  border-color: var(--mainTernaryColor);
  margin: 5px;
  width: 110px;
  border-radius: 10px;

}

.archiveButton:hover {
  background-color: #8fbc8f;
  background-color: var(--mainPrimaryColor);
  border-color: #0e551a;
  border-color: var(--mainSecondaryColor);
  color: #4e9e37;
  color: var(--mainTernaryColor);

}


/*
      Sponsor Page
*/

.sponsorHeading  {
  background-color:  #8fbc8f;
  background-color:  var(--mainPrimaryColor);
  margin-top: 20px;

  margin-bottom: 15px;

  display: inline-block;
  text-align: center;

}

.sponsorHeader {

  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: larger;

}

.sponsorDetail {

  font-weight: bold;
  margin-top: 15px;

}

@charset "UTF-8";
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #999999; }

.rbc-off-range-bg {
  background: #e6e6e6; }

.rbc-agenda-view table.rbc-agenda-table thead > tr > th:nth-child(2){
  display: none;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD; }
  .rbc-header + .rbc-header {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4; }

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    font-size: 28px;
    font-weight: bolder;
    text-align: center; }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: #e6e6e6;
      border-color: #adadad; }
      .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
        color: #373a3c;
        background-color: #d4d4d4;
        border-color: #8c8c8c; }
    .rbc-toolbar button:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }
    .rbc-toolbar button:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: maroon;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left; }
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event.rbc-selected {
    background-color: #0e551a; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal; }

.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #DDD; }

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right; }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden; }

.rbc-day-bg {
  flex: 1 0; }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  color: orange;
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto; }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: center;
      border-bottom: 1px solid #DDD; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  display: none;
}

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' \BB'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '\AB   '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px; }

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7; }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1;
  flex-basis: 0 px; }

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  flex: 1 0; }
  .rbc-time-slot.rbc-now {
    font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0; }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD; }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
    flex-shrink: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative; }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }

.Admin {
    text-align: center;
  
    /* background-color: var(--mainBackgroundColor); */

    background-color: pink;
  
    padding: 10px;
  
  
  }
  
  :root {
      --mainPrimaryColor:#8fbc8f;   
      --mainSecondaryColor: #0e551a;
      --mainTernaryColor: #4e9e37;
  
      --mainBackgroundColor: #d3d3d3; 
  }

  .Collapsible {

    background-color: #0e551a;

    background-color: var(--mainSecondaryColor);
    text-align: left;

    font-size: x-large;

    margin: 10px;
    padding: 10px;

    padding-left: 20px;

    border-style: solid;
    border-color:   #4e9e37;
    border-color:   var(--mainTernaryColor);
    
    border-radius: 10px;

  }

  .Collapsible__contentOuter {
   

    margin: 10px;

  }

  .Collapsible__contentInner {
   
    background-color: #8fbc8f;
   
    background-color: var(--mainPrimaryColor);
    
    margin: 10px;
    padding: 10px;

    padding-left: 20px;

    border-style: solid;
    border-color:   #4e9e37;
    border-color:   var(--mainTernaryColor);
    
    border-radius: 10px;

    text-align: center;
  }

  .adminHeading {
    background-color:  #8fbc8f;
    background-color:  var(--mainPrimaryColor);
    margin-top: 20px;
  
    margin-bottom: 15px;
  
    display: inline-block;
    text-align: center;
  }

  .adminMarginTop {

    margin-top: 20px;
   

  }

  .adminMarginBottom {

    margin-bottom: 20px;
   

  }

  .adminError {

    color: red;

    margin-left: 10px;
    font-size: larger;
    font-weight: bold;
   

  }


/* ToDo: Add the Focus, Visited , etc for AdminText and Admin Button */

  .adminCheck {
    background-color: pink;
  }

  .adminText {

    border-radius: 10px;
    border-style: solid;
    border-color: black;

    border-width: 3px;

  }

  .adminButton {
      background-color: #8fbc8f;
      background-color: var(--mainPrimaryColor);
      color: #0e551a;
      color: var(--mainSecondaryColor);

      border-radius: 10px;
      border-color: black;
      border-width: 2px;

  }

  .adminControl {
      background-color: #0e551a;
      background-color: var(--mainSecondaryColor);

      border-radius: 10px;
      margin: 20px;

  }
