.App {
  text-align: center;

  background-color: var(--mainBackgroundColor);

  padding: 10px;


}

:root {
    --mainPrimaryColor:#8fbc8f;   
    --mainSecondaryColor: #0e551a;
    --mainTernaryColor: #4e9e37;

    --mainBackgroundColor: #d3d3d3; 
}

/*


Default Override


*/


.menuLoginButton {
  color: var(--mainTernaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);
  
  margin: 20px;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;  
  border-width: 1px;
  border-style: solid;

}

.menuLoginButton:focus {
  color: var(--mainTernaryColor);
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainTernaryColor);

  outline: none;
  box-shadow: none;
  
}

.menuLoginButton:link {
  color: var(--mainPrimaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);

}

.menuLoginButton:visited {
  color: var(--mainPrimaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);

}

.menuLoginButton:hover {
  color: var(--mainTernaryColor);
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainTernaryColor);

}

.menuLoginButton:active {
  color: var(--mainPrimaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);

}




.mainContainer{

    background-color: var(--mainPrimaryColor);

    padding-top: 50px;
    padding-bottom: 1px;

}

.mainHeaderCenter {

  margin-top: 20px;

}

.pageMain {

  margin-left: 30px;
  margin-right: 30px;

  padding-top: 5px;
  padding-bottom: 20px; 

}

.pageMain h1 {

  text-align: left;
  padding-left: 20px;


}


.errorColor {
  color: red;

}


/*

    Header Menu Items

*/


.navigationContainer {
  background-color: blue;

}

.menuContainer {
  background-color: var(--mainSecondaryColor);
  margin: 30px;

  display: flex;

}

.menuContainer a:hover {
  color:    var(--mainTernaryColor);
  text-decoration: none;

}

.menuContainer a:link, a:visited, a:active {
  color: var(--mainPrimaryColor);

}

.menuHome {

  background-color: var(--mainSecondaryColor);

  
  border: none;

  margin-top: auto; 
  margin-bottom: auto;

  font-size: 120%;
  margin-right: 10px;


}

.menuItem {
  background-color: var(--mainSecondaryColor);

  /* display: flex; */

  color: var(--mainPrimaryColor);

  margin-top: auto;
  margin-bottom: auto;

  margin-left: 3px;

  border: none;

}

/*

  Login Form login button

*/ 

.loginButton {
  color: var(--mainTernaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);
  
  margin: 20px;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;  
  border-width: 1px;
  border-style: solid;
  
}

.loginButton:focus {
  color: var(--mainTernaryColor);
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainTernaryColor);

  outline: none;
  box-shadow: none;

}

.loginButton:link {
  color: var(--mainPrimaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);

}

.loginButton:visited {
  color: var(--mainPrimaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);

}

.loginButton:hover {
  color: var(--mainTernaryColor);
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainTernaryColor);

}

.loginButton:active {
  color: var(--mainPrimaryColor);
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainTernaryColor);

}


.formLabel {
  width: 100px;
 
  text-align: right;
  margin-left: 20px;
  margin-right: 10px;

  padding: 5px;   

}

.formInput {

  border-radius: 15px;
  padding: 5px;
  padding-left: 10px;

  border-width: 0px;
  
}

.formInput:focus {
 
  outline: none;
  box-shadow: none;

}

.formButton {
  
  outline: none;
 

}

.formLogin {
  margin-top: 50px;

}


.advertiserContainer {
  /* columns: 2 auto; */
  background-color: red;
}

.advertiserHeading  {
  background-color:  var(--mainPrimaryColor);
  margin-top: 20px;

  margin-bottom: 15px;

  display: inline-block;
  text-align: center;

}

.advertiserCell {
    background-color: var(--mainPrimaryColor);
    color: var(--mainSecondaryColor);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;

    border-color: var(--mainTernaryColor);

    height: 300px;
    margin: 10px;
    padding: 10px;
}

.advertiserRow {
  margin-bottom: 2px;
}

.advertiserDetail {
  margin-top: 10px;
}

.advertiserLine {

  border-style: solid;
  border-width: 3px;
  border-radius: 8px;

  border-color: yellow;

  margin-left: 20px;
}

.advertisersAnchor  {
  color: blue;

}


.advertisersAnchor:focus {
  color: lightblue;
  
}

.advertisersAnchor:link {
  color: blue;

}

.advertisersAnchor:visited {
  color: darkblue;

}

.advertisersAnchor:hover {
  color: purple;

}

.advertisersAnchor:active {
  color: navy;
}





.homeContainer {
  margin-top: 20px;
  margin-bottom: 10px;
}

.homeColumnCenter {
    margin-top: auto;
    margin-bottom: auto;

} 

.footerRow {
  background-color:  var(--mainSecondaryColor);
  color: var(--mainTernaryColor);
 
  margin-left: 40px;
  margin-right: 40px;

  margin-top: 20px;
  margin-bottom: 20px;
}

.footerColumn {
   background-color: var(--mainSecondaryColor);
   color: var(--mainTernaryColor);
  
}

/*
  Officers Page
*/
.officersOfficerHeading {
  background-color:  var(--mainPrimaryColor);
  margin-top: 50px;
  
}

.officersTrusteeHeading {
  background-color:  var(--mainPrimaryColor);
  margin-top: 50px;
  
}

.officersTitle {
  background-color:  var(--mainPrimaryColor);
  text-align: right;
  color:  var(--mainSecondaryColor);
  font-size: large;
  font-weight: bolder;

}

.officersOfficer {
  background-color:  var(--mainPrimaryColor);
  text-align: left;
  font-size: large;
  color:  maroon;
  font-weight: bolder;
  
}

.officersListGroupItem {
  background-color:  var(--mainPrimaryColor);
  border-width: 0px;
  font-weight: bolder;
  font-size: large;
  color: maroon;
  padding: 2px;
}


/*
  Home Page
*/
.donationListGroup {
 
  margin-top: 30px;
}

.owlofthemonthListGroup {
 
  margin-top: 30px;
}

.owlofthemonthHeader {

  margin-bottom: 20px;
}

.donationHeader {

  margin-bottom: 20px;
}

.donationListGroupItem {
  background-color:  var(--mainPrimaryColor);
  border-width: 0px;
  font-weight: bolder;
  font-size: large;
  color: maroon;
  padding: 2px;
}

.owlofthemonthListGroupItem {
  background-color:  var(--mainPrimaryColor);
  border-width: 0px;
  font-weight: bolder;
  font-size: large;
  color: maroon;
  padding: 2px;
}

/*

    Archive Page

*/

.archiveHeading  {
  background-color:  var(--mainPrimaryColor);
  margin-top: 20px;

  display: inline-block;
  text-align: center;

}

.archiveYear {

  margin: 30px;
  font-weight: bolder;
  font-size: 36px;
  color: maroon;
}

.archiveButton {
  background-color: var(--mainSecondaryColor);
  color: var(--mainPrimaryColor);
  border-color: var(--mainTernaryColor);
  margin: 5px;
  width: 110px;
  border-radius: 10px;

}

.archiveButton:hover {
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainSecondaryColor);
  color: var(--mainTernaryColor);

}


/*
      Sponsor Page
*/

.sponsorHeading  {
  background-color:  var(--mainPrimaryColor);
  margin-top: 20px;

  margin-bottom: 15px;

  display: inline-block;
  text-align: center;

}

.sponsorHeader {

  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: larger;

}

.sponsorDetail {

  font-weight: bold;
  margin-top: 15px;

}
