.Admin {
    text-align: center;
  
    /* background-color: var(--mainBackgroundColor); */

    background-color: pink;
  
    padding: 10px;
  
  
  }
  
  :root {
      --mainPrimaryColor:#8fbc8f;   
      --mainSecondaryColor: #0e551a;
      --mainTernaryColor: #4e9e37;
  
      --mainBackgroundColor: #d3d3d3; 
  }

  .Collapsible {

    background-color: var(--mainSecondaryColor);
    text-align: left;

    font-size: x-large;

    margin: 10px;
    padding: 10px;

    padding-left: 20px;

    border-style: solid;
    border-color:   var(--mainTernaryColor);
    
    border-radius: 10px;

  }

  .Collapsible__contentOuter {
   

    margin: 10px;

  }

  .Collapsible__contentInner {
   
    background-color: var(--mainPrimaryColor);
    
    margin: 10px;
    padding: 10px;

    padding-left: 20px;

    border-style: solid;
    border-color:   var(--mainTernaryColor);
    
    border-radius: 10px;

    text-align: center;
  }

  .adminHeading {
    background-color:  var(--mainPrimaryColor);
    margin-top: 20px;
  
    margin-bottom: 15px;
  
    display: inline-block;
    text-align: center;
  }

  .adminMarginTop {

    margin-top: 20px;
   

  }

  .adminMarginBottom {

    margin-bottom: 20px;
   

  }

  .adminError {

    color: red;

    margin-left: 10px;
    font-size: larger;
    font-weight: bold;
   

  }


/* ToDo: Add the Focus, Visited , etc for AdminText and Admin Button */

  .adminCheck {
    background-color: pink;
  }

  .adminText {

    border-radius: 10px;
    border-style: solid;
    border-color: black;

    border-width: 3px;

  }

  .adminButton {
      background-color: var(--mainPrimaryColor);
      color: var(--mainSecondaryColor);

      border-radius: 10px;
      border-color: black;
      border-width: 2px;

  }

  .adminControl {
      background-color: var(--mainSecondaryColor);

      border-radius: 10px;
      margin: 20px;

  }